
import { useParams } from 'react-router';
import { useState, useEffect } from "react";
import { Modal } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Share from "../../components/controls/Share.js";
import ButtonWithIcon from '../../components/controls/ButtonWithIcon';
import Capitalize from '../../shared/Capitalize';
import Loader from '../../components/misc/Loader';
import './css/PreciosStockDetalladoPage.css';
import '../../shared/ConditionsProvider'
import { PRECIO_CONDICION } from '../../shared/ConditionsProvider';
import useTitle from '../../shared/hooks/useTitle';
import useMetaTag from '../../shared/hooks/useMetaTag';
import useCanonical from '../../shared/hooks/useCanonical';
import IconAddShoppingCart from '../../components/icons/IconAddShoppingCart';
import IconLocalShipping from '../../components/icons/IconLocalShipping'
import IconSlowMotionVideo from '../../components/icons/IconSlowMotionVideo';
import IconShare from '../../components/icons/IconShare';
import IconHeartPlus from '../../components/icons/IconHeartPlus';
import IconFavorite from '../../components/icons/IconFavorite';
import IconVerifiedUser from '../../components/icons/IconVerifiedUser';
import IconStoreFront from '../../components/icons/IconStoreFront';
import IconBarcode from '../../components/icons/IconBarcode';
import IconSearch from '../../components/icons/IconSearch';
import IconHistory from '../../components/icons/IconHistory';
import IconArrowForwardIos from '../../components/icons/IconArrowForwardIos';
import SwiperSliderRecomendado from './components/SwiperSliderRecomendado';
import { Link } from 'react-router-dom';
import useCarrito from '../../services/intelafApiClient/handler/useCarrito.js';
import useFavorito from '../../services/intelafApiClient/handler/useFavorito.js';
import useProducto from '../../services/intelafApiClient/handler/useProducto.js';
import IconBlock from '../../components/icons/IconBlock.js';
import CuotaBanco from '../../components/misc/CuotasBanco/index.js';
import ProductoExistencia from '../../components/misc/ProductoExistencia/index.js';
import useUsuarioHandler from '../../redux/handler/useUsuarioHandler.js';
import useUltimaActualizacionInventario from '../../services/intelafApiClient/handler/useUltimaActualizacionInventario.js';
import MetodoEntrega from '../../components/misc/MetodoEntrega/index.js';
import NoImage from "../assets/productos/no-image.png";
import CategoriasProductos from './components/CategoriasProductos.js';
import useCategoria from '../../services/intelafApiClient/handler/useCategorias.js';


const PreciosStockDetalladoPage = () => {
    const { codigo } = useParams();

    const { loader: loaderProducto, producto } = useProducto(codigo)
    const { loader: loaderCarrito, disponibilidad, handleAddProductoCarrito } = useCarrito(producto)
    const { loader: loaderFavorito, isFavorito, handleToogleFavorito } = useFavorito(producto)
    const { loader: loaderUpdateDate, ultimaActualizacionInventario } = useUltimaActualizacionInventario()
    const { loader: loaderCategoria, categoria } = useCategoria(codigo)
    const { usuario } = useUsuarioHandler()
    const [errorImage, setErrorImage] = useState(false)

    const [loaderInfo, setLoaderInfo] = useState(true);
    const [openVideo, setOpenVideo]   = useState(false);
    const [openShare, setOpenShare]   = useState(false);


    const [metaDatos, setMetaDatos] = useState({ Title: codigo, Url: "/precios_stock_detallado/" + codigo })

    useTitle(metaDatos.Title)
    useMetaTag(metaDatos)
    useCanonical("/precios_stock_detallado/" + codigo)

    useEffect(() => {
        window.scrollTo(0, 0);
        setErrorImage(false);
    }, [codigo])


    useEffect(() => {
        if(producto) {
            setMetaDatos({
                Title: producto.SeoTitle || producto.Descripcion,
                Description: producto.SeoDescription || producto.AreaFuncionalDescripcion + ", " + producto.Descripcion + ", " + producto.Marca + " Guatemala - Intelaf",
                Keywords: producto.SeoKeywords || producto.AreaFuncionalDescripcion + " " + producto.Marca + ", Guatemala, " + producto.MPN,
                Codigo: producto.Codigo,
                OgTag: true,
                tipo: 'producto'
            });
        }

    }, [producto]);


    const handlerModalVideo = () => {
        setOpenVideo(!openVideo);
    }

    const handleToggleModalShare = () => {
        setOpenShare(!openShare);
    }

    const ResizeIframe = () => {
        // Listen for messages sent from the iFrame
        let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        let eventer = window[eventMethod];
        let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

        eventer(messageEvent, function (e) {
            // If the message is a resize frame request
            try {
                if (e.data.indexOf('resize::') !== -1) {
                    let height = e.data.replace('resize::', '');
                    let frame = document.getElementById('id-iframe-detallado');
                    if (frame) {
                        frame.style.height = (parseFloat(height) + 200).toString() + 'px';
                    }

                }
            } catch (e) {

            }
        }, false);

        setLoaderInfo(false);
        var iframetext = document.getElementById('id-iframe-detallado');
        iframetext.style.display = 'block';

    }

    return (
        <>
            <link rel="preload" fetchpriority="high" as="image" href={"https://www.intelaf.com/images/productos/gran/"+codigo+".jpg"} />
            <div className="container">
                <div className="container-detalle">
                    <div className="detalle-producto" itemScope itemType="https://schema.org/Product">
                        <div className="producto-imagen">
                            <div className='imagen no-select'>
                                {
                                    errorImage === false
                                    ? <img fetchpriority="high" itemProp="image" width={200} height={146} onError={() => { setErrorImage(true) }} src={"https://www.intelaf.com/images/productos/gran/"+codigo+".jpg"} alt={codigo.toLowerCase()} loading="eager"/>
                                    : <img itemprop="image" src={NoImage} alt="No disponible"/>
                                }
    
                            </div>
                        </div>
                        {
                                loaderProducto ? 
                                <>
                                    <Loader></Loader>
                                    <div style={{ height: "200px", width: "100%" }}></div>
                                </> : 
                                <>
                                    <div className="producto-informacion">
                                        <div className="imagen no-select">
                                            <Link to={"/marca/" + producto.Marca.toLowerCase()} style={{ textDecoration: "none", color: "initial" }}>
                                                <img fetchpriority="high" src={producto.LinkMarca} alt={"Imagen marca " + producto.Marca} height={30} width='100%'></img>
                                            </Link>
                                        </div>
                                        <div itemProp="brand" itemScope itemType="https://schema.org/Brand" style={{ width: "0px" }}>
                                            <meta itemProp="name" content={producto.Marca} />
                                        </div>
                                        <p itemProp="name">{producto.Descripcion}</p>
                                        <div className="producto-precio">
                                            {
                                                producto.PrecioAnterior > 0 && usuario.IsMayorista === false ?
                                                    (
                                                        <p className='producto-precio-antes'>
                                                            Precio antes <span>{producto.PrecioAnterior.toCurrency()}</span>
                                                        </p>
                                                    ) : <></>
                                            }
                                            <div className="precio-card">
                                        
                                                {(usuario.IsMayorista === true ?
                                                    <div title='Precio mayorista: Al pagar con efectivo o transferencia' className="precio-normal">
                                                        <h3>Precio mayorista</h3>
                                                        <p>{producto.PrecioNormal.toCurrency()}</p>
                                                    </div>
                                                    : <div title='Precio normal: Al pagar con Tarjeta de Crédito o Débito; Cuotas: Visacuotas, Credomatic' className="precio-normal">
                                                        <h3>Precio normal</h3>
                                                        <p>{producto.PrecioNormal.toCurrency()}</p>
                                                    </div>
                                                )}
                                                {
                                                    (usuario.IsMayorista === false ?
                                                        <>
                                                            <div className="v-linea"></div>
                                                            <div title='Beneficio efectivo o Precio de Oferta: pago en efectivo, transferencia o cheque (con tiempo de espera por liberación)' className="precio-promo">
                                                                <h3>{producto.DescripcionDescuento}</h3>
                                                                <p>{producto.PrecioDescuento.toCurrency()}</p>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                    )
                                                }

                                            </div>
                                            {
                                                producto.DescripcionDescuento !== 'Beneficio Efectivo / ACH' & usuario.IsMayorista === false ?
                                                    (
                                                        <p className='producto-oferta-valida'>Oferta válida hasta: {producto.FechaCaducaDescuento}</p>
                                                    ) : <></>
                                            }
                                            <p className='producto-precio-variable'>{PRECIO_CONDICION}</p>
                                        </div>
                                        <div itemProp="offers" itemScope itemType="https://schema.org/Offer">
                                            <meta itemProp="price" content={producto.PrecioNormal} />
                                            <meta itemProp="priceCurrency" content="GTQ" />      
                                            <meta itemProp="availability" content={  producto.Existencia.length > 0 || producto.EnBodega ? "InStock" : producto.EnTransito ? "BackInStock" : "SoldOut" } />
                                            { producto.EnTransito &&  <meta itemProp="availabilityEnds" content={new Date(producto.FechaTransito).toLocaleDateString('es-GT', { timeZone: 'America/Guatemala', day: '2-digit', month: '2-digit', year: 'numeric' })} /> }
                                            <meta itemProp="preOrder" content="false" /> 
                                        </div>
                                        <div className='container-buttons'>

                                            <MetodoEntrega 
                                                className="Metodo-de-entrega-precios" 
                                                existencias={producto.Existencia}
                                            />

                                            {
                                                producto.PrecioNormal >= 500 & usuario.IsMayorista === false & producto.Existencia.length > 0 ?
                                                    (
                                                        <CuotaBanco codigo={producto.Codigo} />
                                                    ) : <div></div>
                                            }
                                            
                                            <div className='buttons'>
                                                {
                                                    //falta Recien Ingreso
                                                    producto.EnBodega && producto.Existencia.length === 0 && ((new Date() - new Date(producto.FechaCreacion)) / 1000 / 60 / 60 / 24 / 30) <= 3  ? 
                                                    <>
                                                                <ButtonWithIcon
                                                                    text={<>En proceso de  <strong>distribución a tiendas</strong></>}
                                                                    loading={loaderCarrito}
                                                                    height={40}
                                                                    textSize={12}
                                                                    iconSize={19}
                                                                    flexDirection='row'
                                                                    onClick={handleAddProductoCarrito}
                                                                    className='buttonWithIcon-Blue_btn'
                                                                    textMarginRight='11px'
                                                                    textWeight="600"
                                                                />
                                                    </> : 
                                                    <>
                                                        {
                                                            producto.EnTransito && !producto.EnBodega && producto.Existencia.length === 0 ? 
                                                            <>
                                                                <ButtonWithIcon
                                                                    icon={<IconLocalShipping width={19.75} height={20} fill={'#aaa'} />}
                                                                    text='Producto en tránsito'
                                                                    loading={loaderCarrito}
                                                                    height={40}
                                                                    textSize={12}
                                                                    iconSize={19}
                                                                    flexDirection='row'
                                                                    onClick={handleAddProductoCarrito}
                                                                    className='buttonWithIcon-Succces_g'
                                                                    ariaLabel={'No Disponible'}
                                                                />
                                                            
                                                            </> : 
                                                            <>
                                                                {
                                                                    !producto.EnTransito && !producto.EnBodega && producto.Existencia.length === 0 ? 
                                                                    <>
                                                                        <ButtonWithIcon
                                                                            icon={<IconBlock width={19.75} height={20} fill={'#aaa'} />}
                                                                            text='Agotado'
                                                                            loading={loaderCarrito}
                                                                            height={40}
                                                                            textSize={12}
                                                                            iconSize={19}
                                                                            flexDirection='row'
                                                                            onClick={handleAddProductoCarrito}
                                                                            className='buttonWithIcon-WhiteRED'
                                                                            ariaLabel={'Agotado'}
                                                                        />                                                          
                                                                    </> : 
                                                                    <>
                                                                        {

                                                                            disponibilidad ?
                                                                            <>
                                                                                <ButtonWithIcon
                                                                                    icon={<IconAddShoppingCart width={19.75} height={20} fill={'#fff'} />}
                                                                                    text='¡Comprar ahora!'
                                                                                    loading={loaderCarrito}
                                                                                    height={40}
                                                                                    textSize={12.5}
                                                                                    iconSize={20}
                                                                                    flexDirection='row'
                                                                                    onClick={() => { handleAddProductoCarrito(1) }}
                                                                                    className='buttonWithIcon-orange'
                                                                                    ariaLabel={'¡Comprar ahora!'}
                                                                                />
                                                                            </> :
                                                                            <>
                                                                                <ButtonWithIcon
                                                                                    icon={<IconBlock width={19.75} height={20} fill={'#aaa'} />}
                                                                                    text='No Disponible'
                                                                                    loading={loaderCarrito}
                                                                                    height={40}
                                                                                    textSize={12}
                                                                                    iconSize={19}
                                                                                    flexDirection='row'
                                                                                    onClick={handleAddProductoCarrito}
                                                                                    className='buttonWithIcon-whiteghost'
                                                                                    ariaLabel={'No Disponible'}
                                                                                />
                                                                            </>
                                                                        }    
                                                                    </>
                                                                }
                                                            </>
                                                        }                                                    
                                                    </> 
                                                }  
                                
                                                {
                                                    producto.CodigoVideo !== "" ?
                                                        (
                                                            <ButtonWithIcon
                                                                icon={<IconSlowMotionVideo width={19.75} height={20} fill={'#13b4bc'} />}
                                                                text='Mira lo que hace'
                                                                loading={false}
                                                                height={40}
                                                                textSize={12.5}
                                                                iconSize={20}
                                                                onClick={handlerModalVideo}
                                                                className='buttonWithIcon-aqua'
                                                                ariaLabel={'Mira lo que hacer'}
                                                            />
                                                        ) : <></>
                                                }
                                            </div>
                                        </div>
                                        <hr style={{ marginTop: '20px', marginBottom: '15px' }} />
                                        <div className='configuration'>
                                            <ButtonWithIcon
                                                icon={<IconShare width={19.75} height={20} fill={'#aaa'} />}
                                                text='Compartir producto'
                                                loading={false}
                                                height={22}
                                                textSize={12.5}
                                                iconSize={20}
                                                onClick={handleToggleModalShare}
                                                className='buttonWithIcon-link'
                                                ariaLabel={'Compartir producto'}
                                            />
                                            <ButtonWithIcon
                                                icon={isFavorito ? <IconFavorite width={19.75} height={20} fill={'#13b4bc'} /> : <IconHeartPlus width={21.75} height={22} fill={'#aaa'} />}
                                                text={isFavorito ? `Añadido a favoritos` : `Añadir a favoritos`}
                                                loading={loaderFavorito}
                                                height={22}
                                                textSize={12.5}
                                                iconSize={22}
                                                onClick={handleToogleFavorito}
                                                className={isFavorito ? `buttonWithIcon-link_active` : `buttonWithIcon-link`}
                                                ariaLabel={isFavorito ? `Añadido a favoritos` : `Añadir a favoritos`}
                                            />
                                        </div>
                                        <hr style={{ marginTop: '15px', marginBottom: '10px' }} />
                                        <div className='detalle-producto'>
                                            <IconVerifiedUser width={17.75} height={18} fill={'#afafaf'} />
                                            <p>{producto.Garantia} Meses de garantía</p>
                                        </div>
                                        <div className='detalle-producto'>
                                            <IconStoreFront width={17.75} height={18} fill={'#afafaf'} />
                                            <p>Código Intelaf: <span style={{ display: "inline" }} itemProp="productID">{producto.Codigo}</span></p>
                                        </div>
                                        <div className='detalle-producto'>
                                            <IconBarcode width={17.75} height={18} fill={'#afafaf'} />
                                            <p>MPN: <span style={{ display: "inline" }} itemProp="mpn">{producto.MPN}</span></p>
                                        </div>
                                            
                                        <Link to={"/precios_stock_resultado/" + producto.AreaFuncional} style={{ textDecoration: "none", color: "initial" }}>
                                            <div id='ProductosParecidosID' className='detalle-producto' style={{ cursor: 'pointer' }}>
                                                <IconSearch width={17.75} height={18} fill={'#afafaf'} />
                                                <p>Productos parecidos a <span style={{ display: "inline" }} itemProp="category">{Capitalize(producto.AreaFuncionalDescripcion)}</span> </p>
                                            </div>
                                        </Link>
                                        <div className='detalle-producto'>
                                            <IconHistory width={17.75} height={18} fill={'#afafaf'} />
                                            <p>Última actualización de inventario en línea: {loaderUpdateDate ? <>...</> : ultimaActualizacionInventario}</p>
                                        </div>
                                    </div>
                                </>

                        }
                    

                    </div>
                    {
                        loaderProducto ? 
                        <>
                            <Loader></Loader>
                        </> :  
                        <>
                            <div className="detalle-sucursales">
                                <MetodoEntrega 
                                    className="Metodo-de-entrega-sucursales"
                                    existencias={producto.Existencia} 
                                />
                                <br />
                                <ProductoExistencia existencias={producto.Existencia} enBodega={producto.EnBodega} enTransito={producto.EnTransito} fechaTransito={producto.FechaTransito} />
                            </div>
                        </>

                    }
                </div>

                {
                    loaderProducto ? 
                    <>
                        <Loader></Loader>
                    </> :  
                    <>
                        <Link to={"/Similares/" + producto.Codigo.toLowerCase()} style={{ textDecoration: "none", color: "initial" }}>
                            <div style={{ marginTop: "45px", marginBottom: "25px", display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <p style={{ fontSize: "15px", letterSpacing: "-0.035em", fontWeight: "500", margin: '0px', marginRight: '5px', color: "rgba(139, 139, 139, 1)" }}>Otros productos que pueden interesarte </p>
                                <IconArrowForwardIos width={14.75} height={15} fill={'rgb(139, 139, 139)'} />
                            </div>
                        </Link>
                        <div>
                        <SwiperSliderRecomendado productos={producto.ProductosRecomendados} />
                        </div>

                        {/* categorias y caracteristicas */}
                        { 
                            loaderCategoria ?
                            <>
                                <Loader></Loader>
                            </> : 
                            <>
                                { categoria?.length > 0 && <CategoriasProductos categorias={categoria} emptyMessage={'N/A'}/> }
                            </>
                        }
                        
                        {
                            loaderInfo ?
                                <>
                                    <div className="informacion-adicional-producto-loader">
                                        <Loader></Loader>
                                    </div>
                                </> : <></>
                        }

                        <iframe
                            id='id-iframe-detallado'
                            src={`https://api.intelaf.com:2053/App/api/Detalle?codigo=${producto.Codigo}&idProvDetalle=${producto.ProveedorDetallado}&marca=${producto.Marca}&mpn=${producto.MPN}`}
                            title="Información adicional del producto"
                            onLoad={ResizeIframe}
                            className="informacion-adicional-producto"
                        >
                        </iframe>

                        <Modal
                            open={openVideo}
                            onClose={handlerModalVideo}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box>
                                <div className="container-video">
                                    <button onClick={handlerModalVideo}>X</button>
                                    <iframe
                                        className="iframe-video"
                                        src={"https://www.youtube.com/embed/" + producto.CodigoVideo + "?autoplay=1"}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        style={{ border: 'none' }}
                                    >
                                    </iframe>
                                </div>
                            </Box>
                        </Modal>
                    
                        <Modal
                            open={openShare}
                            onClose={handleToggleModalShare}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box>
                                <div style={{ padding: '0px 10px 0px 10px' }}>
                                    <Share onClose={handleToggleModalShare}></Share>
                                </div>
                            </Box>
                        </Modal>
                    </>
                }
            </div>
        </>
    )
}

export default PreciosStockDetalladoPage;                             